/**
 * SEO component that queries for data with
 *  Gatsby"s useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import * as React from "react";
 import { Helmet } from "react-helmet";
 import Logo from "../assets/images/logo.png";
 import utils  from "../helpers/utils";
 
 function Seo(props) {

  const lang = props.lang;

  let title = props.metadata?.metadata?.filter(x => x.property === "title")[0]?.content;
  let description = props.metadata?.metadata?.filter(x => x.property === "description")[0]?.content;

  let image = props.metadata?.metadata?.filter(x => x.property === "image")[0]?.content;

  const banners = props.sections.filter(x => x.strapi_component === 'sections.banner');

  if(image === undefined && banners !== undefined && banners.length > 0) { 
    let bannerImages = banners[0].background?.images;
    if(bannerImages !== undefined && bannerImages.length > 0) {
      let bannerImage = bannerImages[0];
      image = utils.image(bannerImage, "small");
    }
  }

  if(image === undefined) {
    image = Logo;
  }
  
  if(title === undefined) {
    switch(lang) {
      default:
      case "fr":
        title = "Idunn Cotentin | Chambres d'hôtes, ébénisterie & permaculture"
        break;
      case "en":
        title = "Idunn Cotentin | Bed and breakfast, carpentry & permaculture"
        break;
      case "nl":
        title = "Idunn Cotentin | Bed and breakfast, Carpentry & permaculture"
        break;
    }
  }

  if(description === undefined) {
    switch(lang) {
      case "fr":
        description = "Au coeur du Cotentin, Idunn est un endroit en accord avec la nature où nous pratiquons le travail du bois et la permaculture et proposons des chambres d'hôtes."
        break;
      case "en":
        description = "In the Cotentin region, Idunn is a place in harmony with nature where we practice carpentry and permaculture, and offer bed & breakfast."
        break;
      case "nl":
        description = "In the Cotentin region, Idunn is a place in harmony with nature where we practice carpentry and permaculture, and offer bed & breakfast."
        break;
    }
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: "Anne & Kris Matthijs, Clément Joye",
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `twitter:image`,
          content: image,
        },
        {
          name: "robots",
          content: "index, follow"
        }
      ]}
    />
  )
}

export default Seo
 