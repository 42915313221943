import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Menu from "./Menu";
import utils from "../helpers/utils";

const Title = (props) => {

    const title = props.title;

    return title !== undefined 
        ? (<p>{title}</p>)
        : (null);
}

const Subtitle = (props) => {

    const subtitle = props.subtitle;

    return subtitle !== undefined 
        ? (<p>{subtitle}</p>)
        : (null);
}

const Buttons = (props) => {

    const buttons = props.buttons?.filter(x => x.text !== undefined && x.path !== undefined);

    return buttons !== undefined 
        ? (buttons.map( (button, index) => (
            <Link key={index} to={button.path} className="link primary" aria-label={button.text}></Link>
        )))
        : (null);
}

const Container = ({ props, children }) => {

    var background = props.background;

    if(background !== undefined) {

        if(background.images?.length > 0 && background?.color !== undefined) {
            return(
                <article className={`bg-${background?.color}`}>
                    <img src={`${utils.image(background.images[0], "small")}`} alt={background.images[0].alternativeText} style={{width: "100%", position: "absolute", left: 0}} loading="lazy" />
                    {children}
                </article>
            )
        }
        else if(background.images?.length > 0) {
            return(
                <article>
                    <img src={`${utils.image(background.images[0], "small")}`}  alt={background.images[0].alternativeText} style={{width: "100%", position: "absolute", left: 0}} loading="lazy" />
                    {children}
                </article>
            )
        }
        else if(background?.color !== undefined) {
            return (
                <article className={`bg-${background?.color}`}>
                    {children}
                </article>
            )
        }
        else {
            return (
                <article className="bg-gray">
                    {children}
                </article>
            )
        }
    }
}

const Tile = (props) => {
    const tile = props.tile;
    if(tile !== undefined) {
        return(
            <Container props={tile}>
                <header className="major w-auto">
                    <Title title={tile.title} />
                    <Subtitle subtitle={tile.subtitle} />
                </header>
                <Buttons buttons={tile.buttons} />
            </Container>
        )
    }
    return (null);
}

const Tiles = (props) => {

    const id = props.data.section_id;
    const tiles = props.data.tiles;
    
    if(tiles?.length > 0) {
        return (
            <div id="menus">    
                <div className="anchor" id={id}></div>
                <section className="tiles">
                {
                    tiles.map((tile, index) => (
                        <Tile key={index} tile={tile}></Tile>
                    ))
                }
                </section>
            </div>
        )
    }

    return (null);
}

Menu.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Tiles
