import * as React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const MarkdownBlock = (props) => {
    
  const children = props.children;

  return (
    <ReactMarkdown 
      children={children} 
      remarkPlugins={[remarkGfm]}
      linkTarget="_blank" />
  )
}

export default MarkdownBlock;