import React from "react";
import utils  from "../helpers/utils";

const Buttons = (props) => {

    const buttons = props.buttons;

    if(buttons?.length > 0) {
        return(
            <ul className="actions">
                {
                    buttons.map((button, index) => (
                        <li key={index}><a href={button.path} className="button next scrolly">{button.text}</a></li>
                    ))
                }
            </ul>
        )
    }
    else {
        return (null);
    }
}

const Title = (props) => {

    let title = props.title;

    if(title !== undefined) {
        return( <h2>{title}</h2> )
    }

    return (null);
}

const Container = ({ props, children }) => {

    var background = props.background;
    var id = props.section_id;

    if(background?.images !== undefined && background?.images.length > 0) {
        return (
        <section id={id} className="banner major" style={{overflow: "hidden"}}>
            <img src={`${utils.image(background.images[0], "large")}`} alt={background.images[0].alternativeText} style={{width: "100%", position: "absolute", left: 0}} loading="lazy" />
            {children}
        </section>)
    }
    else if (background?.color !== undefined) {
        return(<section id={id} className={`bg-${background?.color}`}>{children}</section>)
    }
    
    return (<section id={id} className="bg-gray">{children}</section>)
}

const Text = (props) => {

    const title = props.data.title;
    const content = props.data.content;
    const contrast = props.data.contrast;
    
    return (
        
        <Container props={props.data}>
            <div className="inner">
                <header className={`major w-auto${contrast === "dark" ? "alt" : ""}`}>
                    <Title title={title}></Title>
                </header>
                <p style={{color: contrast === "light" ? "white" : "black" }}>{content}</p>
                <Buttons buttons={props.data.buttons} />
            </div>
        </Container>
    )
}

export default Text
