import * as React from "react";
import { Link } from "gatsby";
import utils from "../helpers/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MarkdownBlock from "./MarkdownBlock";

const Icon = (props) => {
    
    const icon = props.icon;
    const contrast = props.contrast;

    return icon !== undefined
        ? 
        <span className="icon alt">
            <FontAwesomeIcon icon={utils.icon(icon) ?? undefined} size="1x" style={{color: contrast === "light" ? "white" : "black" }}/>
        </span>
        : (null);   
}

const Title = (props) => {
    
    const title = props.title;
    const contrast = props.contrast;

    return title !== undefined
        ?(<header className={`major w-auto${contrast === "dark" ? "alt" : ""}`}><h3 style={{color: contrast === "light" ? "white" : "black" }}>{title}</h3></header>)
        : (null);
}

const Description = (props) => {
    
    const description = props.description;
    const contrast = props.contrast;

    return (
        <div className="markdown" style={{color: contrast === "light" ? "white" : "black" }}>
            <MarkdownBlock children={description}/>
        </div>
    )
}

const Buttons = (props) => {

    const buttons = props.buttons;
    const contrast = props.contrast;

    if(buttons?.length > 0) {
        return(
            <ul className="actions">
                {
                    buttons.map((button, index) => {
                        if(button.path.includes("mailto:")) {
                            return (
                                <li key={index}><a href={button.path} className={`button ${contrast === "dark" ? "alt" : ""}`}>{button.text}</a></li>
                            )
                        }
                        else {
                            return (
                                <li key={index}><Link to={button.path} className={`button ${contrast === "dark" ? "alt" : ""}`}>{button.text}</Link></li>
                            )
                        }
                    })
                }
            </ul>
        )
    }
    else {
        return (null);
    }
}

const Container = ({ props, children }) => {

    var background = props.background;

    return background?.color !== undefined 
        ? ( <section className={`bg-${background?.color} flex-fill px-5`}>{children}</section> )
        : ( <section className="flex-fill ps-5 pe-3">{children}</section> )
}

const Thumbnail = (props) => {

    const contrast = props.thumbnail.contrast;
    const icon = props.thumbnail.icon;
    const title = props.thumbnail.title;
    const buttons = props.thumbnail.buttons;
    const description = props.thumbnail.description;

    return(
        <Container props={props.thumbnail}>
            <div className="contact-method">
                <Icon icon={icon}/>
                <Title title={title} contrast={contrast}/>
                <Description description={description} contrast={contrast}/>
                <Buttons buttons={buttons} contrast={contrast}/>
            </div>
        </Container>
    )
}

const Thumbnails = (props) => {

    const id = props.data.section_id;
    const thumbnails = props.data.items;

    return(
        <section style={{margin: "-1px"}}>
            <div className="anchor" id={id}></div>
            <section id="contact" className="thumbnails">
                <div className="inner">
                    <section className="split d-flex flex-column p-0">
                    {
                        thumbnails?.map((thumbnail, index) => {
                            if(index % 2 === 0) {
                                return (<Thumbnail key={index} thumbnail={thumbnail} />)
                            }
                            return (null);
                        })
                    }
                    </section>
                    <section className="split d-flex flex-column">
                    {
                        thumbnails?.map((thumbnail, index) => {
                            if(index % 2 === 1) {
                                return (<Thumbnail key={index} thumbnail={thumbnail} />)
                            }
                            return (null);
                        }) 
                    }
                    </section>
                </div>
            </section>
        </section>
    )
}

export default Thumbnails;