import React from "react";
import utils  from "../helpers/utils";

const Buttons = (props) => {

    const buttons = props.buttons;
    const contrast = props.contrast;

    if(buttons?.length > 0) {
        return(
            <ul className="actions">
                {
                    buttons.map((button, index) => (
                        <li key={index}><a href={button.path} className={`button ${contrast === "dark" ? "alt" : ""}`}>{button.text}</a></li>
                    ))
                }
            </ul>
        )
    }
    else {
        return (null);
    }
}

const Container = ({ props, children }) => {

    var background = props.background;
    var id = props.section_id;

    if(background?.images !== undefined && background?.images.length > 0) {
        return (
        <section id={id} className={`banner major`}>
            <img src={`${utils.image(background.images[0], "large")}`} alt={background.images[0].alternativeText} style={{width: "100%", position: "absolute", left: 0}} loading="lazy" />
            {children}
        </section>)
    }
    else if (background?.color !== undefined) {
        return(<section id={id} className={`bg-${background?.color}`}>{children}</section>)
    }
    
    return (<section id={id} className="bg-white">{children}</section>)
}

const Text = (props) => {

    const contrast = props.data.contrast;
    
    return (
        
        <Container props={props.data}>
            <div className="inner text-center">
                <Buttons buttons={props.data.buttons} contrast={contrast} />
            </div>
        </Container>
    )
}

export default Text
