import * as React from "react"
import { MapContainer, TileLayer, Marker, MapConsumer } from 'react-leaflet'
import markerIconPng from "leaflet/dist/images/marker-icon.png"

import * as L from "leaflet";
import { GestureHandling } from "leaflet-gesture-handling";
import LazyLoad from 'react-lazyload';

import 'leaflet/dist/leaflet.css';
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";


const MapContent = (props) => {

    const lat = props.latitude;
    const lng =  props.longitude;
    const zoom =  props.zoom;
    const token = 'pk.eyJ1IjoiY2xlbWVudC1qb3llIiwiYSI6ImNrNXR6NDV6ZzBxM2UzbnBjajE1ZHZ4ZjQifQ.4KoteZT1UlBYvKj_FiT1Mg';
    const mapId = 'cksw2kw8l6nv518quu9gq6bxk'

    const googleMapsLink = props.google_maps;

    if (typeof window !== 'undefined') {
        L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
        return (
            <LazyLoad height={350}>
                <MapContainer id="map" center={[lat, lng]} zoom={zoom} scrollWheelZoom={true}>
                    <MapConsumer>
                        {(map) => { map.gestureHandling.enable(); return (null) }}
                    </MapConsumer>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url={`https://api.mapbox.com/styles/v1/clement-joye/${mapId}/tiles/256/{z}/{x}/{y}@2x?access_token=${token}`}
                    />
                    <Marker 
                        position={[lat, lng]}
                        icon={new L.Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}
                        eventHandlers={{
                            click: (e) => {
                                window.open(googleMapsLink, "_blank")
                            },
                        }}>
                    </Marker>
                    
                </MapContainer>
            </LazyLoad>
        );
    }
    else {
        return null;
    }
}

const Split = (props) => {

    return(
        <MapContent 
            latitude={props.data.latitude} 
            longitude={props.data.longitude} 
            zoom={props.data.zoom} 
            google_maps={props.data.google_maps} />
    )
}

const Map = (props) => {

    const id = props.data.section_id;

    return (
        <div>
            <div className="anchor" id={id}></div>
            <section className="spotlights">
                <Split data={props.data}></Split>
            </section>
        </div>
    )
}

export default Map
