import React from "react";
import PropTypes from "prop-types";
import Menu from "./Menu";
import Masonry from 'react-masonry-css'
import { Carousel, Collapse } from "react-bootstrap";
import { StaticQuery, graphql, Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudSun } from "@fortawesome/free-solid-svg-icons";
import { faWind } from "@fortawesome/free-solid-svg-icons";
import { faTint } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { faSeedling } from "@fortawesome/free-solid-svg-icons";
import { faCarrot } from "@fortawesome/free-solid-svg-icons";
import { faLemon } from "@fortawesome/free-solid-svg-icons";
import { faPepperHot } from "@fortawesome/free-solid-svg-icons";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import Default from "../assets/images/default.jpg";
import utils  from "../helpers/utils";

const query = graphql`query GardenProductsQuery {
    allStrapiGardenProducts {
        edges {
            node {
                name
                latin_name
                description
                category
                price
                in_stock
                images {
                    alternativeText
                    url
                    formats {
                        small {
                            url
                            width
                            height
                        }
                    }
                }
                exposure { sun partial_shade shade }
                hardiness { hardy not_hardy }
                soil { sandy sandy_loam loamy humus clayey }
                humidity {dry normal humid }
                longevity { annual bi_annual perennial }
                use {edible medicinal melliferous }
                availability {
                    name
                    price
                    available
                }
            }
        }
    }
    allStrapiDictionaries {
        edges {
            node {
                name
                value
                locale
            }
        }
    }
}`;

const GetExposure = (exposure, dictionaries) => {
    
    var values = [];
    
    if(exposure) {
        if(exposure.sun) {
            values.push(dictionaries.sun);
        }
        if(exposure.partial_shade) {
            values.push(dictionaries.partial_shade);
        }
        if(exposure.shade) {
            values.push(dictionaries.shade);
        }
    }

    return values.join(" / ");
}

const GetHardiness = (hardiness, dictionaries) => {
    
    var values = [];
    
    if(hardiness) {
        if(hardiness.hardy) {
            values.push(dictionaries.hardy);
        }
        if(hardiness.not_hardy) {
            values.push(dictionaries.not_hardy);
        }
    }
    
    return values.join(" / ");
}

const GetSoil = (soil, dictionaries) => {
    
    var values = [];
    
    if(soil) {
        if(soil.sandy) {
            values.push(dictionaries.sandy);
        }
        if(soil.sandy_loam) {
            values.push(dictionaries.sandy_loam);
        }
        if(soil.loamy) {
            values.push(dictionaries.loamy);
        }
        if(soil.humus) {
            values.push(dictionaries.humus);
        }
        if(soil.clayey) {
            values.push(dictionaries.clayey);
        }
    }
    
    return values.join(" / ");
}

const GetHumidity = (humidity, dictionaries) => {
    
    var values = [];
    
    if(humidity) {
        if(humidity.dry) {
            values.push(dictionaries.dry);
        }
        if(humidity.normal) {
            values.push(dictionaries.normal);
        }
        if(humidity.humid) {
            values.push(dictionaries.humid);
        }
    }
    
    return values.join(" / ");
}

const GetLongevity = (longevity, dictionaries) => {
    
    var values = [];
    
    if(longevity) {
        if(longevity.annual) {
            values.push(dictionaries.annual);
        }
        if(longevity.bi_annual) {
            values.push(dictionaries.biannual);
        }
        if(longevity.perennial) {
            values.push(dictionaries.perennial);
        }
    }
    
    return values.join(" / ");
}

const GetUse = (use, dictionaries) => {
    var values = [];
    
    if(use) {
        if(use.edible) {
            values.push(dictionaries.edible);
        }
        if(use.medicinal) {
            values.push(dictionaries.medicinal);
        }
        if(use.melliferous) {
            values.push(dictionaries.melliferous);
        }
    }
    
    return values.join(" / ");
}

const ThumbnailCollapseButtonInfo = (props) => {

    const dictionaries = props.dictionaries;
    const open = props.open;
    const setOpen = props.setOpen;

    const [info, setInfo] = React.useState(dictionaries.more_info);

    const onClick = () => {

        setOpen(!open);

        if(info === dictionaries.more_info) {
            setInfo(dictionaries.less_info);
        }
        else {
            setInfo(dictionaries.more_info);
        }
    }

    return ( <button className="button mb-0 small" onClick={onClick} aria-controls="collapse" aria-expanded={open}>{info}</button> )
}

const ThumbnailCollapseContentInfo = (props) => {

    const dictionaries = props.dictionaries;
    const availability = props.availability;
    const open = props.open;

    let prices = [];

    availability?.forEach(x => {
        prices.push({icon: faTag, name: dictionaries[x.name], price: x.price, available: x.available});
    })

    const rows = [
        {icon: faCloudSun, name: dictionaries.exposure, value: GetExposure(props.exposure, dictionaries)},
        {icon: faWind, name: dictionaries.hardiness, value: GetHardiness(props.hardiness, dictionaries)},
        {icon: faSeedling, name: dictionaries.soil, value: GetSoil(props.soil, dictionaries)},
        {icon: faTint, name: dictionaries.humidity, value: GetHumidity(props.humidity, dictionaries)},
        {icon: faClock, name: dictionaries.longevity, value: GetLongevity(props.longevity, dictionaries)},
        {icon: faQuestion, name: dictionaries.use, value: GetUse(props.use, dictionaries)},
    ];

    return (
        <Collapse in={open}>
            <div className="mt-4">
                <div className="table-wrapper fs-6">
                    <h5 className="ms-1"><u>{dictionaries.price}</u></h5>
                    <table className="small" style={{fontSize: '0.8em'}}>
                        <tbody>
                            {
                                prices.map((row, index) => (
                                    <tr key={index}>
                                        <td><FontAwesomeIcon icon={row.icon} size="1x"/></td>
                                        <td style={{textDecoration: `${row.available ? '' : 'line-through'}`}}>{row.name}</td>
                                        <td style={{textDecoration: `${row.available ? '' : 'line-through'}`}}>{row.price}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <h5 className="ms-1"><u>{dictionaries.caracteristics}</u></h5>
                    <table className="small mb-3" style={{fontSize: '0.8em'}}>
                        <tbody>
                            {
                                rows.map((row, index) => (
                                    <tr key={index}>
                                        <td><FontAwesomeIcon icon={row.icon} size="1x"/></td>
                                        <td>{row.name}</td>
                                        <td>{row.value}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </Collapse>
    )
}

const ThumbnailImages = (props) => {
    const images = props.images;
    const name = props.name;
    
    if(images !== undefined && images.length > 0) {

        if(images.length === 1) {
            return (
                <div className="image-container">
                    <img className="rounded" src={utils.image(images[0], "small")} alt={images[0].alternativeText} loading="lazy" /> 
                </div>
            )
        }
        else if(images.length > 1) {
            return (
                <div className="image-container">
                    <Carousel className="carousel h-100 w-100" fade={true} nextLabel="" prevLabel="" indicators={false} controls={true} interval={null}>
                    {
                        images.map((x, index) => (
                            <Carousel.Item key={index} className="h-100" >
                                <img className="rounded" src={utils.image(x, "small")} alt={x.alt} loading="lazy" />
                            </Carousel.Item>
                        ))
                    }
                    </Carousel>
                </div>
            )
        }
    }
    return(
        <div className="image-container">
            <img className="rounded" src={Default} alt={name} loading="lazy" />
        </div>
    )
}

const ThumbnailIcon = (props) => {
    
    let faIcon = undefined;
    const icon = props.icon;

    switch(icon) {
        case "herb":
            faIcon = faSeedling;
            break;
        case "vegetable":
            faIcon = faCarrot;
            break;
        case "small_fruit":
            faIcon = faLemon;
            break;
        case "tomato":
            faIcon = faPepperHot;
            break;
        default:
            faIcon = faInfo;
            break;
    }

    return faIcon !== undefined
        ? ( <span className="icon alt me-2"><FontAwesomeIcon icon={faIcon ?? undefined} size="1x" style={{minWidth: '32px'}}/></span> )
        : (null);
}

const ThumbnailTitle = (props) => {
    
    const title = props.title;
    const contrast = props.contrast;

    return title !== undefined
        ?(<header className={`major mb-0 ${contrast === "dark" ? "alt" : ""}`} style={{width: 'fit-content'}}><h5 style={{color: contrast === "light" ? "white" : "black" }}>{title}</h5></header>)
        : (null);
}

const ThumbnailLatin = (props) => {
    
    const latin = props.latin;
    return (
        <h6 className="d-block text-black ms-3 mt-1 me-1"><i>— {latin}</i></h6>
    )
}

const Thumbnail = (props) => {

    const icon = props.thumbnail.category;
    const images = props.thumbnail.images;
    const title = props.thumbnail.name;
    const latin_name = props.thumbnail.latin_name;
    const inStock = props.thumbnail.in_stock;
    const availability = props.thumbnail.availability;
    const lowest = availability !== null && availability.length > 0 ? availability[0].price : undefined;
    const exposure = props.thumbnail.exposure;
    const hardiness = props.thumbnail.hardiness;
    const soil = props.thumbnail.soil;
    const humidity = props.thumbnail.humidity;
    const longevity = props.thumbnail.longevity;
    const use = props.thumbnail.use;
    const dictionaries = props.dictionaries;
    
    const [openInfo, setOpenInfo] = React.useState(false);

    return(
        <section className="p-0 my-2 mb-5">
            <div className="product-main d-flex flex-column">
                <ThumbnailImages images={images} />
                <div className="d-flex justify-content-between flex-column p-1 info-container">
                    <div className="d-flex flex-row my-2">
                        <ThumbnailIcon icon={icon} />
                        <ThumbnailTitle title={title} />
                        <ThumbnailLatin latin={latin_name} />
                    </div>
                    <div className="d-flex justify-content-between">
                        <ThumbnailCollapseButtonInfo dictionaries={dictionaries} open={openInfo} setOpen={setOpenInfo}/>
                        <h6 className="pt-1 mb-0 text-uppercase align-self-center fw-light">{lowest !== undefined ? `${dictionaries.from} ${lowest}` : ''}</h6>
                        <p className={`badge rounded-pill align-self-center px-2 py-2 mb-0 bg-${inStock ? "secondary" : "primary" }`}>{inStock ? dictionaries.in_stock : dictionaries.out_of_stock}</p>
                    </div>
                </div>
            </div>
            <ThumbnailCollapseContentInfo 
                dictionaries={dictionaries} 
                exposure={exposure} 
                hardiness={hardiness} 
                soil={soil} 
                humidity={humidity} 
                longevity={longevity} 
                use={use} 
                open={openInfo} 
                availability={availability} />
        </section>
    )
}

const TileTitle = (props) => {

    const title = props.title;

    return title !== undefined 
        ? (<p>{title}</p>)
        : (null);
}

const TileSubtitle = (props) => {

    const subtitle = props.subtitle;

    return subtitle !== undefined 
        ? (<p>{subtitle}</p>)
        : (null);
}

const TileButtons = (props) => {

    const buttons = props.buttons?.filter(x => x.text !== undefined && x.path !== undefined);
    const onFilteringChanged = props.onFilteringChanged;

    return buttons !== undefined 
        ? (buttons.map( (button, index) => (
            <Link key={index} data-value={button.path} to="#list" className="link primary" onClick={onFilteringChanged} aria-label={button.text}></Link>
        )))
        : (null);
}

const TileContainer = ({ props, children }) => {

    var background = props.background;

    if(background !== undefined) {

        if(background.images?.length > 0 && background?.color !== undefined) {
            return(
                <article className={`bg-${background?.color}`}>
                    <img src={`${utils.image(background.images[0], "small")}`} alt={background.images[0].alternativeText} style={{width: "100%", position: "absolute", left: 0}} loading="lazy" />
                    {children}
                </article>
            )
        }
        else if(background.images?.length > 0) {
            return(
                <article>
                    <img src={`${utils.image(background.images[0], "small")}`} alt={background.images[0].alternativeText} style={{width: "100%", position: "absolute", left: 0}} loading="lazy" />
                    {children}
                </article>
            )
        }
        else if(background?.color !== undefined) {
            return (
                <article className={`bg-${background?.color}`}>
                    {children}
                </article>
            )
        }
        else {
            return (
                <article>
                    {children}
                </article>
            )
        }
    }
}

const Tile = (props) => {

    const tile = props.tile;
    const onFilteringChanged = props.onFilteringChanged;

    if(tile !== undefined) {
        return(
            <TileContainer props={tile}>
                <header className="major w-auto">
                    <TileTitle title={tile.title} />
                    <TileSubtitle title={tile.subtitle} />
                </header>
                <TileButtons buttons={tile.buttons} onFilteringChanged={onFilteringChanged} />
            </TileContainer>
        )
    }

    return (null);
}

const Tiles = (props) => {

    const id = props.id;
    const tiles = props.tiles;
    const onFilteringChanged = props.onFilteringChanged;
    
    if(tiles?.length > 0) {
        return (
            <>
                <div className="anchor" id={id}></div>
                <section className="tiles">
                {
                    tiles.map((tile, index) => (
                        <Tile key={index} tile={tile} onFilteringChanged={onFilteringChanged}></Tile>
                    ))
                }
                </section>
            </>
        )
    }

    return (null);
}

const Thumbnails = (props) => {

    const thumbnails = props.products;
    const background = props.background;
    
    console.log(props.dictionaries)

    const dictionaries = { 
        in_stock: props.dictionaries.filter(x => x.node.name === "In stock")[0].node.value,
        out_of_stock: props.dictionaries.filter(x => x.node.name === "Out of stock")[0].node.value,
        more_info: props.dictionaries.filter(x => x.node.name === "More info")[0].node.value,
        less_info: props.dictionaries.filter(x => x.node.name === "Less info")[0].node.value,
        exposure: props.dictionaries.filter(x => x.node.name === "Exposure")[0].node.value,
        hardiness: props.dictionaries.filter(x => x.node.name === "Hardiness")[0].node.value,
        soil: props.dictionaries.filter(x => x.node.name === "Soil")[0].node.value,
        longevity: props.dictionaries.filter(x => x.node.name === "Longevity")[0].node.value,
        humidity: props.dictionaries.filter(x => x.node.name === "Humidity")[0].node.value,
        use: props.dictionaries.filter(x => x.node.name === "Use")[0].node.value,

        sun: props.dictionaries.filter(x => x.node.name === "Sun")[0].node.value,
        partial_shade: props.dictionaries.filter(x => x.node.name === "Partial shade")[0].node.value,
        shade: props.dictionaries.filter(x => x.node.name === "Shade")[0].node.value,
        hardy: props.dictionaries.filter(x => x.node.name === "Hardy")[0].node.value,
        not_hardy: props.dictionaries.filter(x => x.node.name === "Not hardy")[0].node.value,
        sandy: props.dictionaries.filter(x => x.node.name === "Sandy")[0].node.value,
        sandy_loam: props.dictionaries.filter(x => x.node.name === "Sandy loam")[0].node.value,
        loamy: props.dictionaries.filter(x => x.node.name === "Loamy")[0].node.value,
        humus: props.dictionaries.filter(x => x.node.name === "Humus")[0].node.value,
        clayey: props.dictionaries.filter(x => x.node.name === "Clayey")[0].node.value,
        dry: props.dictionaries.filter(x => x.node.name === "Dry")[0].node.value,
        normal: props.dictionaries.filter(x => x.node.name === "Normal")[0].node.value,
        humid: props.dictionaries.filter(x => x.node.name === "Humid")[0].node.value,
        annual: props.dictionaries.filter(x => x.node.name === "Annual")[0].node.value,
        biannual: props.dictionaries.filter(x => x.node.name === "Biannual")[0].node.value,
        perennial: props.dictionaries.filter(x => x.node.name === "Perennial")[0].node.value,
        edible: props.dictionaries.filter(x => x.node.name === "Edible")[0].node.value,
        medicinal: props.dictionaries.filter(x => x.node.name === "Medicinal")[0].node.value,
        melliferous: props.dictionaries.filter(x => x.node.name === "Melliferous")[0].node.value,
        
        caracteristics: props.dictionaries.filter(x => x.node.name === "Caracteristics")[0].node.value,
        price: props.dictionaries.filter(x => x.node.name === "Price")[0].node.value,
        from: props.dictionaries.filter(x => x.node.name === "From")[0].node.value,
        _9cm: props.dictionaries.filter(x => x.node.name === "_9cm")[0].node.value,
        _11cm: props.dictionaries.filter(x => x.node.name === "_11cm")[0].node.value,
        _1L: props.dictionaries.filter(x => x.node.name === "_1L")[0].node.value,
        sup_1L: props.dictionaries.filter(x => x.node.name === "sup_1L")[0].node.value,
    };

    const breakpointColumnsObj = {
        default: 4,
        1200: 3,
        900: 2,
        500: 1
      };
    
    return(
        <section className={`px-3 bg-${background?.color ?? "gray"}`}>
            <section id="contact" className="thumbnails">
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">
                {
                    thumbnails?.map((thumbnail, index) => (
                        <Thumbnail key={index} thumbnail={thumbnail} dictionaries={dictionaries} />
                    ))
                }
            </Masonry>
            </section>
        </section>
    )
}

const Filtering = (props) => {

    const onSortingChanged = props.onSortingChanged;
    const onSearchChanged = props.onSearchChanged;
    const background = props.background;

    const dictionaries = { 
        sort: props.dictionaries.filter(x => x.node.name === "Sort")[0].node.value,
    };

    const sorting = [
        { name: "A - Z", value: "alphabetical_asc" },
        { name: "Z - A", value: "alphabetical_desc" },
    ];

    return(
        <section className={`bg-${background?.color ?? "gray"}`}>
            <div className="d-flex flex-row">
                <div className="col-6 d-inline-block">
                    <div className="select-wrapper d-block">
                        <select name="sorting" id="sorting" onChange={onSortingChanged} style={{color: "#000000"}}>
                            <option value="">- {dictionaries.sort} -</option>
                            {
                                sorting.map((option, index) => (
                                    <option key={index} value={option.value}>{option.name}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="col-6 d-inline-block">
                    <input type="text" name="search" id="search" placeholder="Search" style={{color: "#000000"}} aria-label="Search" onChange={onSearchChanged}/>
                </div>
            </div>
        </section>
    )
}

const HerbsCatalog = (props) => {
    
    const categories = props.data.categories;
    const id = props.data.section_id;
    const background = props.data.background;
    const locale = props.locale;

    const [searchState, setSearchState] = React.useState("");
    const [filteringState, setFilteringState] = React.useState(categories[0].buttons[0].path);
    const [sortingState, setSortingState] = React.useState("alphabetical_asc");
    
    const onSortingChanged = event => {
        var type = event.target.value !== "" ? event.target.value : "alphabetical_asc";
        currentSorting = type;
        setSortingState(currentSorting);
    }

    const onFilteringChanged = event => {
        var type = event.target.dataset.value;
        currentFiltering = type;
        setFilteringState(currentFiltering);
    }

    const onSearchChanged = event => {
        var search = event.target.value;
        currentSearch = search;
        setSearchState(currentSearch);
    }

    const sortingTypes = {
        alphabetical_asc: {
          fn: (a, b) => (a.node.name > b.node.name) ? 1 : ((b.node.name > a.node.name) ? -1 : 0)
        },
        alphabetical_desc: {
          fn: (a, b) => (b.node.name > a.node.name) ? 1 : ((a.node.name > b.node.name) ? -1 : 0)
        }
    };

    var currentSorting = sortingState;
    var currentFiltering = filteringState;
    var currentSearch = searchState;
    
    return(
        <section id="products" className={`bg-${background?.color ?? "gray"}`}>
            <Tiles tiles={categories} id={id} onFilteringChanged={onFilteringChanged}/>
            <StaticQuery query={query} render=
            {
                data => (
                    <>
                        <Filtering background={background} onSortingChanged={onSortingChanged} onSearchChanged={onSearchChanged} dictionaries={data.allStrapiDictionaries.edges}/>
                        <div className="anchor" id="list"></div>
                        <Thumbnails products={data.allStrapiGardenProducts.edges
                            .filter(x => x.node.category === currentFiltering)
                            .filter(x => x.node.name.toLowerCase().includes(currentSearch.toLowerCase()))
                            .sort(sortingTypes[currentSorting].fn)
                            .map(x => x.node)} dictionaries={data.allStrapiDictionaries.edges.filter(x => x.node.locale === locale)} background={background} />
                    </>
                )
            }
            />
        </section>
    )
}

Menu.propTypes = {
    onToggleMenu: PropTypes.func
}

export default HerbsCatalog
