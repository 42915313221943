import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Section from "../components/Section";
import Seo from "../components/Seo";

export const query = graphql`
  query Page($url: String!, $locale: String!) {
    page: strapiPages(url: { eq: $url }, locale: {eq: $locale}) {
      name
      url
      metadata {
        metadata {
          property
          content
        }
      }
      locale
      sections
    }
  }
`;

const Style = ({ props, children }) => {
  return (
    <div className="d-flex flex-column w-100 h-100" style={{marginTop: "4.75em"}}>
      {children}
    </div>
  )
}

const Page = ({ data }) => {
  const locale = data.page.locale;
  const metadata = data.page.metadata;
  const sections = data.page.sections;

  return (
    <Layout locale={locale}>
      <Seo sections={sections} metadata={metadata} lang={locale}/>
      <Style props={data}>
        { 
          sections.map((section, key) => (
            <Section key={key} type={section.strapi_component} section={section} locale={locale}></Section>
          ))
        }
      </Style>
    </Layout>
  );
};

export default Page;