import * as React from "react";
import Gallery from "react-grid-gallery";
import MarkdownBlock from "./MarkdownBlock";
import utils  from "../helpers/utils";
import LazyLoad from 'react-lazyload';

const Description = (props) => {

    const description = props.description;
    const contrast = props.contrast;

    return description 
    ? (<div className="markdown text-start ps-1" style={{color: contrast === "light" ? "white" : "black" }}><MarkdownBlock children={description}/></div>)
    : (null);
}

const Title = (props) => {

    const title = props.title;
    const contrast = props.contrast;

    return title 
    ? (<h3 className="mb-4" style={{color: contrast === "light" ? "white" : "black" }}>{title}</h3>)
    : (null);
}

const Container = ({ props, children }) => {

    var background = props.background;

    return background !== undefined 
        ? ( <section className={`text-center px-3 pb-5 bg-${background ?? "white"}`}>{children}</section> )
        : ( <section className="text-center px-3 pb-5">{children}</section> )
}

const GridGallery = (props) => {
    const images = props.data.images;
    const contrast = props.data.contrast;
    const title = props.data.title;
    const description = props.data.description;
    const gallery = [];

    if(images === undefined) {
        return (null);
    }

    images.forEach(image => {
        gallery.push({
            src: utils.image(image, 'large'),
            thumbnail: image.formats?.small?.url,
            thumbnailWidth: image.formats?.small?.width,
            thumbnailHeight: image.formats?.small?.height,
            caption: image.caption,
            thumbnailCaption: (<span style={{color: "white"}}>{image.alternativeText}</span>),
            alt: image.alternativeText,
        })
    })

    return(
        <Container props={props.data}>
            <LazyLoad height={400}>
                <div className="portfolio w-auto m-auto mt-4 justify-content-center">
                    <Title title={title} contrast={contrast} />
                    <Description description={description} contrast={contrast} />
                    <Gallery 
                        enableImageSelection={false} 
                        backdropClosesModal={true} 
                        preloadNextImage={true} 
                        showLightboxThumbnails={true} 
                        images={gallery}/>
                    <hr className="border-bottom border-2"/>
                </div>
            </LazyLoad>
        </Container>
    )
}

export default GridGallery;
