import * as React from "react"
import Banner from "./Banner";
import Tiles from "./Tiles";
import Spotlights from "./Spotlights";
import Text from "./Text";
import Thumbnails from "./Thumbnails";
import GridGallery from "./GridGallery";
import Portfolio from "./Portfolio";
import HerbsCatalog from "./HerbsCatalog";
import Newsletter from "./Newsletter";
import Button from "./Button";
import Map from "./Map.js";
import BookingForm from "./BookingForm.js";
import Markdown from "./Markdown";

const SwitchSection = (props) => {
    
    const section = props.section;
    const type = props.type;
    const locale = props.locale;

    switch(type) {
        case "sections.banner":
            return (<Banner data={section} locale={locale} />)
        case "sections.tiles":
            return (<Tiles data={section} locale={locale} />)
        case "sections.spotlights":
            return (<Spotlights data={section} locale={locale} />)
        case "sections.text":
            return (<Text data={section} locale={locale} />)
        case "sections.thumbnails":
            return (<Thumbnails data={section} locale={locale} />)
        case "sections.grid-gallery":
            return (<GridGallery data={section} locale={locale} />)
        case "sections.herbs-catalog":
            return (<HerbsCatalog data={section} locale={locale} />)
        case "sections.portfolio":
            return (<Portfolio data={section} locale={locale} />)
        case "sections.newsletter":
            return (<Newsletter data={section} locale={locale} />)
        case "sections.button":
            return (<Button data={section} locale={locale} />)
        case "sections.map":
            return (<Map data={section} locale={locale} />)
        case "sections.booking-form":
            return (<BookingForm data={section} locale={locale} />)
        case "sections.markdown":
            return (<Markdown data={section} locale={locale} />)
        default:
            return (null);
    }
}

const Section = (props) => {

    const section = props.section;
    const type = props.type;
    const locale = props.locale;

    return(
        <SwitchSection section={section} type={type} locale={locale}></SwitchSection>
    )
}

export default Section;