import * as React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const Markdown = (props) => {
    
  const text = props.data.text;
  const contrast = props.data.contrast;

  return (
    <div className="markdown p-5" style={{color: contrast === "light" ? "white" : "black" }}>
        <ReactMarkdown 
          className={`${contrast === "text-dark" ? "text-light" : ""}`}
          children={text} 
          remarkPlugins={[remarkGfm]}
          linkTarget="_blank" />
    </div>
  )
}

export default Markdown;