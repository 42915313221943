import React from "react";
import ReactMarkdown from "react-markdown";
import DatePicker from "react-datepicker";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Spinner } from "react-bootstrap";
import LazyLoad from 'react-lazyload';

import "react-datepicker/dist/react-datepicker.css";

const Title = (props) => {
    
    const title = props.title;
    const contrast = props.contrast;

    return title !== undefined
        ?(<header className={`major ${contrast === "dark" ? "alt" : ""}`}><h2 style={{color: contrast === "light" ? "white" : "black" }}>{title}</h2></header>)
        : (null);
}

const Description = (props) => {
    
    const description = props.description;
    const contrast = props.contrast;

    return (
        <div className="markdown" style={{color: contrast === "light" ? "white" : "black" }}>
            <ReactMarkdown children={description} />
        </div>
    )
}

const BookingForm = (props) => {

    console.log(props);

    const title = props.data.title;
    const description = props.data.description;
    const firstNameStr = props.data.firstname;
    const lastNameStr = props.data.lastname;
    const phoneStr = props.data.phone;
    const emailStr = props.data.email;
    const arrivalStr = props.data.arrival_date;
    const departureStr = props.data.departure_date;
    const accomodationStr = props.data.accomodation;
    const chambreIdunnStr = props.data.chambre_idunn;
    const studioAbellioStr = props.data.studio_abellio;
    const messageStr = props.data.message;
    const contrast = props.data.contrast;
    const background = props.data.background;
    const success_message = props.data.success_message;
    const locale = props.locale;

    const [startDate, setStartDate] = React.useState();
    const [endDate, setEndDate] = React.useState();

    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [accomodation, setAccomodation] = React.useState(studioAbellioStr);
    const [message, setMessage] = React.useState('');
    const [disableButton, setDisableButton] = React.useState(false);
    const [submit, setSubmit] = React.useState(props.data.submit);
    const [isLoading, setIsLoading] = React.useState(false);

    const { executeRecaptcha } = useGoogleReCaptcha()
    const [notification, setNotification] = React.useState("")

    const handleSubmit = async (e) => {
        e.preventDefault()

        // Check if the captcha was skipped or not
        if (!executeRecaptcha) {
            return
        }

        if (!email) {
            setNotification(`Email is required.`);
        }
        else if (!phone) {
            setNotification(`Phone is required.`);
        }
        else if (!firstName) {
            setNotification(`First name is required.`);
        }
        else if (!lastName) {
            setNotification(`Last name is required.`);
        }
        else if (!startDate) {
            setNotification(`Start date is required.`);
        }
        else if (!endDate) {
            setNotification(`End date is required.`);
        }
        else if (!message) {
            setNotification(`Message is required.`);
        }
        else {
            setNotification(``);
        }
        
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (!pattern.test(email)) {
            setNotification(`Please enter valid email address.`);
        }

        else if(firstName.length > 255) {
            setNotification(`First name is too long.`);
        }

        else if(lastName.length > 255) {
            setNotification(`Last name is too long.`);
        }

        else if(message.length > 5000) {
            setNotification(`Message is too long.`);
        }

        let startDateTranslated = "";
        let endDateTranslated = "";

        if(locale == 'fr' || locale == 'nl') {
            startDateTranslated = new Date(startDate).toLocaleDateString('fr-FR');
            endDateTranslated = new Date(endDate).toLocaleDateString('fr-FR');
        }
        else {
            startDateTranslated = new Date(startDate).toISOString().split('T')[0];
            endDateTranslated = new Date(endDate).toISOString().split('T')[0];
        }

        // This is the same as grecaptcha.execute on traditional html script tags
        await executeRecaptcha("homepage")

        const data = JSON.stringify({
            name: `${firstName} ${lastName}`,  
            email: email, 
            phone: phone, 
            startDate: startDateTranslated, 
            endDate: endDateTranslated, 
            accomodation: accomodation,
            message: message,
            locale: locale
        });

        const azureFunctionUrl = `/api/booking-email`;

        setDisableButton(true);
        setIsLoading(true);

        fetch(azureFunctionUrl, {
            method: "POST",
            body: data,
        })
        .then(res => {
            if(res.status === 200) {
                setDisableButton(true);
                setIsLoading(false);
                setSubmit(data.message);
                setNotification(success_message);
            }
            else {
                setNotification(data.message)
                setIsLoading(false);
                setDisableButton(false);
            }
        })
    }

    return (
        <section id="booking" className="spotlights bg-gray">
            <div className="anchor" id="booking-form"></div>
            <LazyLoad height={680}>
                <section className={`bg-${background?.color ?? "gray"}`}>
                    <form onSubmit={handleSubmit} className="px-5 py-5 mb-0 bg-dark w-100">
                        <Title title={title} contrast={contrast}/>
                        <Description description={description} contrast={contrast}/>
                        <div className="field half ps-0">
                            <label htmlFor="lastname">{lastNameStr}</label>
                            <input type="text" name="lastname" id="lastname" onChange={event => setLastName(event.target.value)} disabled={disableButton} required />
                        </div>
                        <div className="field half">
                            <label htmlFor="firstname">{firstNameStr}</label>
                            <input type="text" name="firstname" id="firstname" onChange={event => setFirstName(event.target.value)} disabled={disableButton} required />
                        </div>
                        <div className="field half ps-0">
                            <label htmlFor="email">{emailStr}</label>
                            <input type="email" name="email" id="email" onChange={event => setEmail(event.target.value)} disabled={disableButton} required />
                        </div>
                        <div className="field half">
                            <label htmlFor="phone">{phoneStr}</label>
                            <input type="tel" name="phone" id="phone" onChange={event => setPhone(event.target.value)} disabled={disableButton} required />
                        </div>
                        <div className="field half ps-0">
                            <label htmlFor="arrival">{arrivalStr}</label>
                            <DatePicker name="arrival" selected={startDate} onChange={(date) => setStartDate(date)} />
                        </div>
                        <div className="field half">
                            <label htmlFor="departure">{departureStr}</label>
                            <DatePicker name="departure" selected={endDate} onChange={(date) => setEndDate(date)} />
                        </div>
                        <div className="field">
                            <label htmlFor="name">{accomodationStr}</label>
                            <fieldset className="d-inline-block field">
                                <input 
                                    type="radio" id="studio_abellio" name="studio_abellio" 
                                    value="studioAbellioStr" 
                                    checked={accomodation === studioAbellioStr}
                                    onChange={() => setAccomodation(studioAbellioStr)} />
                                <label htmlFor="studio_abellio">{studioAbellioStr}</label>
                                <input 
                                    type="radio" id="chambre_idunn" name="chambre_idunn" 
                                    value="chambreIdunnStr"
                                    checked={accomodation === chambreIdunnStr}
                                    onChange={() => setAccomodation(chambreIdunnStr)} />
                                <label htmlFor="chambre_idunn">{chambreIdunnStr}</label>
                            </fieldset>
                        </div>
                        <div className="field">
                            <label htmlFor="message">{messageStr}</label>
                            <textarea name="message" id="message" onChange={event => setMessage(event.target.value)} disabled={disableButton} required />
                        </div>
                        <ul className="actions">
                            <li><input type="submit" className="special" value={submit} disabled={disableButton}/></li>
                            <li>
                                <Spinner className={`${isLoading ? 'd-inline-block' : 'd-none'}`} animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </li>
                            {notification && <span>{notification}</span>}
                        </ul>
                        <div className="anchor" id="our-booking-form"></div>
                    </form>
                </section>    
            </LazyLoad>
        </section>
    )
}

export default BookingForm
