import React from "react";
import ReactMarkdown from "react-markdown";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Spinner } from "react-bootstrap";
import LazyLoad from 'react-lazyload';

const Title = (props) => {
    
    const title = props.title;
    const contrast = props.contrast;

    return title !== undefined
        ?(<header className={`major ${contrast === "dark" ? "alt" : ""}`}><h2 style={{color: contrast === "light" ? "white" : "black" }}>{title}</h2></header>)
        : (null);
}

const Description = (props) => {
    
    const description = props.description;
    const contrast = props.contrast;

    return (
        <div className="markdown" style={{color: contrast === "light" ? "white" : "black" }}>
            <ReactMarkdown children={description} />
        </div>
    )
}

const Newsletter = (props) => {

    const title = props.data.title;
    const description = props.data.description;
    const contrast = props.data.contrast;
    const background = props.data.background;
    const success_message = props.data.success_message;

    const [email, setEmail] = React.useState('');
    const [disableButton, setDisableButton] = React.useState(false);
    const [submit, setSubmit] = React.useState(props.data.submit);
    const [isLoading, setIsLoading] = React.useState(false);

    const { executeRecaptcha } = useGoogleReCaptcha()
    const [notification, setNotification] = React.useState("")

    const handleSubmit = async (e) => {
        e.preventDefault()

        // Check if the captcha was skipped or not
        if (!executeRecaptcha) {
            return
        }

        if (!email) {
            setNotification(`Email is required.`);
        }
        else {
            setNotification(``);
        }
        
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (!pattern.test(email)) {
            setNotification(`Please enter valid email address.`);
        }
        
        // This is the same as grecaptcha.execute on traditional html script tags
        const result = await executeRecaptcha("homepage")

        const data = JSON.stringify({ email: email, token: result })

        const azureFunctionUrl = `/api/mailchimp-subscribe`;

        setDisableButton(true);
        setIsLoading(true);

        fetch(azureFunctionUrl, {
            method: "POST",
            body: data,
        })
        .then(res => {
            if(res.status === 200) {
                setDisableButton(true);
                setIsLoading(false);
                setSubmit(data.message);
                setNotification(success_message);
            }
            else {
                setNotification(data.message)
                setIsLoading(false);
                setDisableButton(false);
            }
        })
    }

    return (
        <section id="newsletter" className="spotlights bg-gray">
            <div className="anchor" id="our-newsletter"></div>
            <LazyLoad height={400}>
                <section className={`bg-${background?.color ?? "gray"}`}>
                    <form onSubmit={handleSubmit} className="px-5 py-5 mb-0 bg-dark w-100">
                        <Title title={title} contrast={contrast}/>
                        <Description description={description} contrast={contrast}/>
                        <div className="field">
                            <label htmlFor="email">Email</label>
                            <input type="email" name="email" id="email" onChange={event => setEmail(event.target.value)} disabled={disableButton} required />
                        </div>
                        <ul className="actions">
                            <li><input type="submit" className="special" value={submit} disabled={disableButton}/></li>
                            <li>
                                <Spinner className={`${isLoading ? 'd-inline-block' : 'd-none'}`} animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </li>
                            {notification && <span>{notification}</span>}
                        </ul>
                    </form>
                </section>
            </LazyLoad>
        </section>
    )
}

export default Newsletter
