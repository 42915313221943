import React from "react";
import { Link} from "gatsby";
import Logo from "../assets/images/logo.png";
import utils  from "../helpers/utils";

const Buttons = (props) => {

    const buttons = props.buttons;
    const contrast = props.contrast;
    
    if(buttons?.length > 0) {
        return(
            <ul className="actions">
                {
                    buttons.map((button, index) => (
                        <li key={index}><Link key={index} to={button.path} className={`button next scrolly ${contrast === "dark" ? "alt" : ""}`} aria-label={button.text}>{button.text}</Link></li>
                    ))
                }
            </ul>
        )
    }
    else {
        return (null);
    }
}

const Title = (props) => {

    let title = props.title;

    if(title !== undefined && title !== "") {
        return(
            <h1>{title}</h1>
        )
    }

    return (
        <h1>
            <img className="d-inline title" src={Logo} alt="Idunn logo" style={{height: "auto"}} loading="lazy" />
        </h1>
    )
}

const Banner = (props) => {

    const id = props.data.section_id;
    const title = props.data.title;
    const subtitle = props.data.subtitle;
    
    const image = props.data.background?.images?.length > 0 
        ? props.data.background.images[0]
        : undefined;

    return (
        <>
            <div className="anchor" id={id}></div>
            <section className="banner major" style={{overflow: "hidden"}}>
                <img src={`${utils.image(image, "large")}`} alt={image.alternativeText} style={{width: "100%", position: "absolute", left: 0, top: 0, height: "100%", objectFit: 'cover'}} loading="lazy" />
                <div className="inner">
                    <header className="major w-auto">
                        <Title title={title} />
                    </header>
                    <div className="content">
                        <p>{subtitle}</p>
                        <Buttons buttons={props.data.buttons} contrast={props.data.contrast} />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Banner
