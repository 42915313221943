import * as React from "react";
import { Link } from "gatsby";
import { Carousel } from "react-bootstrap";
import MarkdownBlock from "./MarkdownBlock";
import utils  from "../helpers/utils";
import LazyLoad from 'react-lazyload';


const Images = (props) => {

    const images = props.images;

    if(images?.length == 1) {
        return(
            <div className="image">
                <img src={utils.image(images[0], "medium")} alt={images[0].alternativeText} loading="lazy"/>
            </div>
        )
    }
    else if(images?.length > 1) {
        return (
            <div className="image">
                <Carousel className="carousel h-100 w-100" fade={true} controls={false} indicators={false} interval={5000}>
                {
                    images.map((x, index) => (
                        <Carousel.Item key={index} className="h-100" >
                            <img className="rounded" src={utils.image(x, "medium")} alt={x.alt} loading="lazy" />
                        </Carousel.Item>
                    ))
                }
                </Carousel>
            </div>
        )
    }

    return (null);
}

const Title = (props) => {
    
    const title = props.title;
    const contrast = props.contrast;

    return title !== undefined
        ?(<header className={`major w-auto ${contrast === "dark" ? "alt" : ""}`}><h2 style={{color: contrast === "light" ? "white" : "black" }}>{title}</h2></header>)
        : (null);
}

const Description = (props) => {
    
    const description = props.description;
    const contrast = props.contrast;

    return (
        <div className="markdown" style={{color: contrast === "light" ? "white" : "black" }}>
            <MarkdownBlock children={description}/>
        </div>
    )
}

const Buttons = (props) => {

    const buttons = props.buttons;
    const contrast = props.contrast;

    if(buttons?.length > 0) {
        return(
            <ul className="actions">
                {
                    buttons.map((button, index) => (
                        <li key={index}><Link to={button.path} className={`button ${contrast === "dark" ? "alt" : ""}`}>{button.text}</Link></li>
                    ))
                }
            </ul>
        )
    }
    else {
        return (null);
    }
}

const Container = ({ props, children }) => {

    var background = props.background;

    return background?.color !== undefined 
        ? ( <section className={`bg-${background?.color}`}>{children}</section> )
        : ( <section className="bg-gray">{children}</section> )
}

const Split = (props) => {

    const images = props.split.images;
    const contrast = props.split.contrast;
    const title = props.split.title;
    const buttons = props.split.buttons;
    const description = props.split.description;

    return(
        <Container props={props.split}>
            <Images images={images} />
            <div className="content">
                <div className="inner">
                    <Title title={title} contrast={contrast}/>
                    <Description description={description} contrast={contrast}/>
                    <Buttons buttons={buttons} contrast={contrast} />
                </div>
            </div>
        </Container>
    )
}

const Spotlights = (props) => {

    const id = props.data.section_id;
    const splits = props.data.splits;

    return(
        <div>
            <div className="anchor" id={id}></div>
            <LazyLoad height={320}>
                <section className="spotlights">
                {
                    splits?.map((split, index) => (
                        <Split key={index} split={split}></Split>
                    ))   
                }
                </section>
            </LazyLoad>
        </div>
    )
}

export default Spotlights;