import React from "react";
import PropTypes from "prop-types";
import Menu from "./Menu";
import { useLocation } from '@reach/router';
import { StaticQuery, graphql, Link } from "gatsby";
import Gallery from "react-grid-gallery";
import utils  from "../helpers/utils";
import LazyLoad from 'react-lazyload';

const query = graphql`query PortfolioQuery {
    allStrapiPortfolios {
        edges {
            node {
                name
                date
                description
                category
                order
                images {
                    alternativeText
                    url
                    caption
                    formats {
                        small {
                            url
                            width
                            height
                        }
                        medium {
                            url
                            width
                            height
                        }
                        large {
                            url
                            width
                            height
                        }
                        xlarge {
                            url
                            width
                            height
                        }
                    }
                }
            }
        }
    }
}`;

const TileTitle = (props) => {

    const title = props.title;

    return title !== undefined 
        ? (<p>{title}</p>)
        : (null);
}

const TileSubtitle = (props) => {

    const subtitle = props.subtitle;

    return subtitle !== undefined 
        ? (<p>{subtitle}</p>)
        : (null);
}

const TileButtons = (props) => {

    const buttons = props.buttons?.filter(x => x.text !== undefined && x.path !== undefined);
    const onFilteringChanged = props.onFilteringChanged;

    return buttons !== undefined 
        ? (buttons.map( (button, index) => (
            <Link key={index} data-value={button.path} to="#portfolio" className="link primary" onClick={onFilteringChanged} aria-label={button.text}></Link>
        )))
        : (null);
}

const TileContainer = ({ props, children }) => {

    var background = props.background;

    if(background !== undefined) {

        if(background.images?.length > 0 && background?.color !== undefined) {
            return(
                <article className={`bg-${background?.color}`}>
                    <img src={`${utils.image(background.images[0], "small")}`} alt={background.images[0].alternativeText} style={{width: "100%", position: "absolute", left: 0}} loading="lazy" />
                    {children}
                </article>
            )
        }
        else if(background.images?.length > 0) {
            return(
                <article>
                    <img src={`${utils.image(background.images[0], "small")}`} alt={background.images[0].alternativeText} style={{width: "100%", position: "absolute", left: 0}} loading="lazy" />
                    {children}
                </article>
            )
        }
        else if(background?.color !== undefined) {
            return (
                <article className={`bg-${background?.color}`}>
                    {children}
                </article>
            )
        }
        else {
            return (
                <article>
                    {children}
                </article>
            )
        }
    }
}

const Tile = (props) => {

    const tile = props.tile;
    const onFilteringChanged = props.onFilteringChanged;

    if(tile !== undefined) {
        return(
            <TileContainer props={tile}>
                <header className="major">
                    <TileTitle title={tile.title} />
                    <TileSubtitle title={tile.subtitle} />
                </header>
                <TileButtons buttons={tile.buttons} onFilteringChanged={onFilteringChanged} />
            </TileContainer>
        )
    }

    return (null);
}

const Tiles = (props) => {

    const id = props.id;
    const tiles = props.tiles;
    const onFilteringChanged = props.onFilteringChanged;
    
    if(tiles?.length > 0) {
        return (
            <>
                <div className="anchor" id={id}></div>
                <section className="tiles">
                {
                    tiles.map((tile, index) => (
                        <Tile key={index} tile={tile} onFilteringChanged={onFilteringChanged}></Tile>
                    ))
                }
                </section>
            </>
        )
    }

    return (null);
}

const GridGallery = (props) => {

    const items = props.items;
    const background = props.background;

    return (
        <section className={`text-center p-5 bg-${background?.color ?? "white"}`}>
        {
            items.map((item, index) => {
                
                let gallery = [];

                item.images.forEach(image => {
                    gallery.push({
                        src: utils.image(image, 'large'),
                        thumbnail: image.formats?.small?.url,
                        thumbnailWidth: image.formats?.small?.width,
                        thumbnailHeight: image.formats?.small?.height,
                        caption: image.caption,
                        thumbnailCaption: (<span style={{color: "white"}}>{image.alternativeText}</span>),
                        alt: image.alternativeText,
                    })
                })

                return (
                    <LazyLoad height={215}>
                        <div key={index} className="portfolio w-auto m-auto mt-4 justify-content-center">
                            <p className="mb-4">{item.description}</p>
                            <Gallery 
                                enableImageSelection={false} 
                                backdropClosesModal={true} 
                                preloadNextImage={true} 
                                showLightboxThumbnails={true} 
                                images={gallery}/>
                            <hr className="border-bottom border-2"/>
                        </div>
                    </LazyLoad>
                )
            })
        }
        </section>
    )
}

const Portfolio = (props) => {
    
    let params = useLocation().search?.split('=');
    
    let collection = undefined;
    
    const categories = props.data.categories;
    const id = props.data.section_id;
    const background = props.data.background;

    const [filteringState, setFilteringState] = React.useState(collection ? collection : categories[0].buttons[0].path);

    const onFilteringChanged = event => {
        var type = event.target.dataset.value;
        currentFiltering = type;
        setFilteringState(currentFiltering);
    }
    
    var currentFiltering = filteringState;

    if(params.length > 1) {
        collection = params[1];
        currentFiltering = collection;
    }
    
    return(
        <section id="products" className={`bg-${background?.color ?? "white"}`}>
            <Tiles tiles={categories} id={id} onFilteringChanged={onFilteringChanged} />
            <StaticQuery query={query} render={ data => (
            <>
                <div className="anchor" id="list"></div>
                <GridGallery background={background} items={data.allStrapiPortfolios.edges
                        .filter(x => x.node.category === currentFiltering)
                        .sort((a, b) => { return (a.node.order ?? 0) - (b.node.order ?? 0) } )
                        .map(x => x.node)}></GridGallery>
            </>
            )} />
        </section>
    )
}



Menu.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Portfolio
